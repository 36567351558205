import { logger } from '@shared/utils';
import type { HandleClientError } from '@sveltejs/kit';

export const handleError = (({ error }) => {
	const pwaError = error as App.Error | undefined;
	// const errorId = crypto.randomUUID();

	logger.error('hooks.client:handleError', error);

	return {
		// errorId,
		message: pwaError?.message ?? 'Internal Server Error',
		type: pwaError?.type ?? 'unknown-error',
	};
}) satisfies HandleClientError;
