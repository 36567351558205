import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/[[locale]]/auth/authenticate": [~6],
		"/[[locale]]/auth/userMgmt": [7],
		"/[[locale]]/invite": [~8],
		"/[[locale]]/login": [~9],
		"/[[locale]]/projects/[[projectId]]": [~10],
		"/[[locale]]/register": [~11],
		"/[[locale]]/(settings)/settings/account": [~4,[2]],
		"/[[locale]]/(settings)/settings/workspace": [~5,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';